'use strict';
/* eslint-disable */
var dialog = require('_core_ext/dialog'),
    productStoreInventory = require('_core/storeinventory/product'),
    util = require('_core_ext/util'),
    addToCart = require('./addToCart'),
    availability = require('_core/pages/product/availability'),
    productNav = require('_core_ext/pages/product/productNav'),
    productSet = require('_core_ext/pages/product/productSet'),
    variant = require('./variant');
    import slickSlider from '../../components/common/SlickSlider';
    import layout from '_core_ext/layout';

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
    productNav();
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $pdpMain = $('#pdpMain');
    var $pdpBottomAddtoCartSticky = $('.pdpBottomAddtoCartSticky');

    addToCart.init();
    availability();
    variant.init();
    productSet.init();
    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });

    $(document).on('click', '.js-share-icon', function (e) {
        $(this).siblings('.js-share-content').toggleClass('is--hidden');
    });

    $(document).on('click', '.shopthelook-btn', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $("#shopthelook").offset().top+100
        }, 800);
    });

    if(layout.isMobileView()){
        slickSlider.slidePDPMainImage($('#product-images .images-container'));
        var mobStickyCTAHeight = parseFloat($('.c-product-details__sticky-cta-block').outerHeight());
    }

    $(window).on('load', function() {
        if(layout.isMobileView()){
            const imageOffsetHeight= $('.js-product-images-wrap').offset().top;
            const imageWrapHeight = $('.js-product-images-wrap').outerHeight();
            const newCTAHeight= parseFloat($('.c-product-details__sticky-cta-block').outerHeight());
            const totalSlideHeight= (imageOffsetHeight + (imageWrapHeight + newCTAHeight)) - window.innerHeight;
            setTimeout(function () {  
                if ($(window).scrollTop() >= totalSlideHeight) {
                    $('.c-product-details__slider-progress-count').removeClass('keepFixed');
                } else {
                    $('.c-product-details__slider-progress-count').addClass('keepFixed');
                }
            }, 500);
        }
    });

    $(window).on('scroll', function () {
        var top = $(window).scrollTop(),
            divBottom = $('.pdp-info-add-to-cart').offset().top + $('.pdp-info-add-to-cart').outerHeight();
        if (divBottom > top) {
            $pdpBottomAddtoCartSticky.removeClass('intoToTheView');
        } else {
            $pdpBottomAddtoCartSticky.addClass('intoToTheView');
        }

        if(layout.isMobileView()){
            const imageOffsetHeight= $('.js-product-images-wrap').offset().top;
            const imageWrapHeight = $('.js-product-images-wrap').outerHeight();
            const newCTAHeight= parseFloat($('.c-product-details__sticky-cta-block').outerHeight());
            const totalSlideHeight= (imageOffsetHeight + (imageWrapHeight + newCTAHeight)) - window.innerHeight;
            if (top >= totalSlideHeight) {
                $('.c-product-details__slider-progress-count').removeClass('keepFixed');
            } else {
                $('.c-product-details__slider-progress-count').addClass('keepFixed');
            }
        }  
    });
}

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
    }
};

module.exports = product;
