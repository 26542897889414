'use strict';

const ajax = require('./../../../../../app_storefront_core_ext/cartridge/js/ajax'),
    util = require('./../../../../../app_storefront_core_ext/cartridge/js/util');
var googleRecaptcha = require('./../../../../../app_storefront_core_ext/cartridge/js/googleRecaptcha');


let $cache = {},
    options = {},
    selectors = {
        emailmeButton: '.js-emailme',
        emailmeCloseButton: '.js-emailme-close',
        emailmeForm: '.js-emailme-form',
        emailmeContainer: '.js-email-me-container',
        pdpForm: '.js-pdpForm',
        productContent: '.js-product-content',
        notifyme: '.js-notifyme',
        pdpStickyBlock: '.js-product-details-sticky',
        stickynotifyme: '.js-notifyme-sticky',
        policynotifyme: '.js-notifyme-policies',
        notifyEmailContent: '.js-notifyemail-content',
        notifyEmailModal: '#notifyMeModal'
    };

function initializeConfig(params) {
    options = $.extend({}, options, params);
}

function initializeCache() {
    $cache.document = $(document);
}

function isCaptchaValid() {
    if ($('.g-recaptcha-response').val() !== '' || grecaptcha.enterprise.getResponse() !== '') {
        return true;
    } else {
        if ($('.g-recaptcha-response').length) {
            grecaptcha.enterprise.reset();
        }
        return false;
    }
}

function handleEmailMe($form, $submitAction) {
    $form.on('submit', (e) => {
        e.preventDefault();

        if (!$form.valid()) {
            return;
        }

        if ($('.recaptcha-verify').length  && $('.js-notifyme-acceptlettersubscription').is(':checked') === true) {
            e.preventDefault();
            if (!isCaptchaValid()) {
                googleRecaptcha.buildRecaptchaV2();
                return;
            } else {
                googleRecaptcha.getRecaptchaResponse().then(
                    function (value) {
                        if ($subscribeForm.parents("form").valid()) {
                            $subscribeForm.parents("form").submit();
                        } else {
                            googleRecaptcha.buildRecaptchaV2();
                            return;
                        }
                    });
            }
        }

        var submitName = $submitAction.attr('name');
        var submitValue = $submitAction.attr('value');
        var formData = $form.serialize() + '&' + submitName + '=' + submitValue;
        var body = document.body;

        ajax({
            url: $form.attr('action'),
            type: 'POST',
            data: formData
        }).then((response) => {
            $cache.document.find(selectors.notifyEmailContent).html(util.jsonParse(response).message);
            if(response !== undefined && response !== '') {
                $cache.document.find(selectors.notifyEmailContent).addClass('success');                
                $cache.document.find(selectors.emailmeCloseButton).hide();
                
                setTimeout(function() {
                    $cache.document.find(selectors.notifyEmailModal).hide();
                    body.classList.remove("modal-open");
                    $cache.document.find(selectors.emailmeCloseButton).show();
                }, 4000);

                $form.trigger('reset');
            }
        });
    });
}

function emailMeProductSet() {
    $cache.document.on('click', selectors.emailmeButton, (e) => {
        e.preventDefault();
        var $current = $(e.currentTarget);

        ajax({
            url: $current.data('href')
        }).then((response) => {
            $current
                .closest(selectors.productContent)
                .find(selectors.emailmeContainer).html(response);
            $current
                .closest(selectors.productContent)
                .find(selectors.pdpForm).addClass('is-hidden');

            var $form = $current.closest(selectors.productContent).find(selectors.emailmeForm),
                $submitAction = $form.find(':submit');

            $form.trigger('reset');

            handleEmailMe($form, $submitAction);
        });
    });

    $cache.document.on('click', selectors.emailmeCloseButton, (e) => {
        var $current = $(e.currentTarget);
        $current
            .closest(selectors.productContent)
            .find(selectors.pdpForm).removeClass('is-hidden');
        $current
            .parents(selectors.emailmeContainer).empty();
    });

    $cache.document.find(selectors.emailmeForm)
        .each(function () {
            var $form = $(this),
                $submitAction = $form.find(':submit');
            handleEmailMe($form, $submitAction);
        });
}

function emailMeProduct() {

    $cache.document.on('click', selectors.emailmeButton, (e) => {
        e.preventDefault();
        var url = util.appendParamsToUrl($(e.currentTarget).data('href'), {
            'page': 'emailme'
        });
        ajax({
            url: url
        }).then((response) => {
            var $emailmeContainer = $cache.document.find(selectors.emailmeContainer);
            $emailmeContainer.html(response);
            $cache.document.find(selectors.notifyEmailContent).removeClass('success');
            $cache.document.find(selectors.policynotifyme).siblings().find('a').prop('target', '_blank');


            var body = document.body;

            // Get the modal
            var modal = document.getElementById("notifyMeModal");

            // Get the modal content
            var modalContent = document.getElementById("notifyme-modal-content");

            // Get the <span> element that closes the modal
            var span = document.getElementById("js-notify-emailme-close");

            // When the user clicks the button, open the modal
            modal.style.display = "block";
            body.classList.add("modal-open");

            // When the user clicks on <span> (x), close the modal
            span.onclick = function () {
                modal.style.display = "none";
                body.classList.remove("modal-open");
            }

            // When the user clicks anywhere outside of the modal, close it
            window.onclick = function (event) {
                $('.notifyemail-content').on('click', 'input[type="checkbox"].js-notifyme-acceptlettersubscription, input[type="checkbox"].js-notifyme-policies', function () {
                    if ($('.js-notifyme-acceptlettersubscription').is(':checked') === true && $('.js-notifyme-policies').is(':checked') === true) {
                        $('.js-toggle_content.c-subscribe-form__toggle').show();
                        $('.js-toggle_content.c-subscribe-form__toggle input').removeAttr('disabled');
                    }
                    else {
                        $('.js-toggle_content.c-subscribe-form__toggle').hide();
                        $('.js-toggle_content.c-subscribe-form__toggle input').attr('disabled','disabled');
                    }
                });
                if (event.target == modalContent || event.target == modal) {
                    modal.style.display = "none";
                    body.classList.remove("modal-open");
                    $cache.document.find(selectors.notifyme).addClass('is--hidden');
                }
            }

            var $form = $cache.document.find(selectors.emailmeForm),
                $submitAction = $cache.document.find(selectors.notifyme);

            $form.trigger('reset');
            $('.g-recaptcha').css('display', 'block');
            handleEmailMe($form, $submitAction);
        });
    });

    $cache.document.on('click', selectors.emailmeCloseButton, () => {
        $cache.document.find(selectors.emailmeContainer).empty();
        $cache.document.find(selectors.notifyme).addClass('is--hidden');
        body.classList.remove("modal-open");
    });

    $cache.document.on('click', selectors.stickynotifyme, (e) => {
        e.preventDefault();
        $cache.document.find(selectors.emailmeForm).submit();
        $cache.document.find(selectors.notifyme).each(function() {
            $(this).prop('disabled', true);
        });
    });

    if ($cache.document.find(selectors.emailmeForm).length) {
        var $form = $cache.document.find(selectors.emailmeForm),
            $submitAction = $cache.document.find(selectors.notifyme);
        handleEmailMe($form, $submitAction);
    }
}

function initializeEvents() {
    if (pageContext.currentPage === 'productSet') {
        emailMeProductSet();
    } else {
        emailMeProduct();
    }

    $cache.document.on('productset.variation.changed', emailMeProductSet);
}

let emailme = {
    init: function (params) {
        initializeConfig(params);
        initializeCache();
        initializeEvents();
    }
};

module.exports = emailme;
