/** The references object of all modules needed for components initialization */
var references = {
    /** Page module references */
    'product': require('./pages/product'),
    'searchsuggest': require('./searchsuggest'),
    'common-elements': require('./common-elements'),
    'zoom': require('./components/common/zoom'),
    'productMainImageNav': require('./../../../app_storefront_core_ext/cartridge/js/components/product/ProductMainImageNav').default,
    'stickyElement': require('./components/common/StickyElement'),
    'slickSlider': require('./components/common/SlickSlider'),
    'newsletterForm': require('./components/forms/NewsletterForm').default,
    'headerContainer': require('./components/header/Header').default,
    'headerSearchContainer': require('./components/header/HeaderSearch').default,
    'minicart': require('./minicart'),
    'variant': require('./pages/product/variant'),
    'addToCart': require('./pages/product/addToCart'),
    'cart': require('../js/pages/cart'),
    'checkout': require('../js/pages/checkout'),
    'picture-lazy-load': require('./picture-lazy-load'),
    'backToTop': require('./components/common/BackToTop').default,
    /** PDP */
    'pdp': require('./components/pdp/Pdp').default,
    'emailme': require('./pages/product/emailme'),
    'productTileCmp': require('./components/common/productTileCmp').default,
    /** PLP */
    'plp': require('./components/plp/Plp').default,
    'refinement': require('./components/plp/refinements/Refinement').default,
    'refinementsContainer': require('./components/plp/refinements/Container').default,
    'productList': require('./components/plp/productList/ProductList').default,
    'productTile': require('./components/plp/productTile/ProductTile').default,
    'quickShop': require('./components/plp/productTile/quickShop/QuickShop').default,
    'notifyMe': require('./components/plp/notifyMe/NotifyMe').default,
    /** Checkout */
    'checkoutCustom': require('./components/checkout/CustomCheckout'),
    'shippingFormsContainer': require('./components/checkout/shipping/shippingForms/Container').default,
    /** Shipping */
	'sendingShippingForm': require('./components/checkout/shipping/shippingForms/Sendingshippingforms').default,
    /** Account */
    'customAccordion': require('./components/common/CustomAccordion').default,
    /**Order Confirmation */
    'registeraccount': require('./components/registeraccount')
};

/** The components initialization configuration object */
var configuration = {
    'global' : {
        'components': {
            'slickSlider': {},
            'variant': {},
            'addToCart': {},
            'productTileCmp': {},
            'productTile': {},
            'quickShop': {}
        }
    },
    'search' : {
        'components': {
            'productTileCmp': {}
        }
    },
    'orderconfirmation' : {
        'components': {
            'loyaltyJoin': {},
            'passwordResetForm': {},
            'registeraccount': {}
        }
    },
    'product': {
        'components': {
            'productMainImageNav': {enabled: true},
            'pdp': {},
            'productTileCmp': {},
            'productTile': {},
            'quickShop': {}
        }
    },
    'cart': {
        'components': {
            'productTileCmp': {},
            'productTile': {},
            'quickShop': {}
        }
    },
    'checkout': {
        'components': {
            'checkoutCustom': {},
            'sendingShippingForm': {}
        }
    },
    'account': {
        'components': {
            'customAccordion': {}
        }
    }
};

module.exports = {
    'configuration' : configuration,
    'references' : references
};