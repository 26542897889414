'use strict';

var dialog = require('_core_ext/dialog'),
    minicart = require('../../minicart'),
    cart = require('../cart'),
    page = require('_core_ext/page'),
    util = require('_core_ext/util'),
    Promise = require('promise'),
    _ = require('lodash');

import eventMgr from '_core_ext/core/eventMgr';
import ajax from '_core_ext/ajax';
import layout from '_core_ext/layout';
import progress from '_core/progress';

let $cache = {},
    selectors = {
        addToCart: '.add-to-cart',
        addAllToCart: '#add-all-to-cart',
        selectAllOptions: '.select-all-options',
        addToCartDisabled: '.add-to-cart[disabled]',
        selectAllOptionsSticky: '.js-select-all-options',
        productVariations: '.js-product-variations',
        productStickyClose: '.js-product-details-sticky-close',
        stickyVariationsBlock: '.pdpBottomAddtoCartSticky .js-product-details-sticky .c-product-details__sticky-variations-block',
        pdpBottomAddtoCartSticky: '.pdpBottomAddtoCartSticky',
        stickySizeGuideLink: '.js-sticky-size-guide-link',
        productVariationsLabelSuggestion: '.c-product-variations__labelSuggestion',
        productVariationsLabelError: '.c-product-variations__labelError',
        header: '.js-header',
        productStickyVariationTitle: '.pdpBottomAddtoCartSticky .c-product-details__sticky-variation-title',
        productStickyVariationList: '.pdpBottomAddtoCartSticky .c-product-details__sticky .c-product-variations__list',
        productDetailsStickyWrapper: '.pdpBottomAddtoCartSticky .c-product-details__sticky',
        jsSizeGuideLink: '.js-size-guide-link',        
        pdpMainAddToBagCTA: '.js-pdp-main-add-to-bag'
    };

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function (form) {
    var $form = $(form),
        $qty = $form.find('input[name="Quantity"]');
    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }
    if(window.sessionStorage.productPosition){
        $form.find('#productPosition').val(window.sessionStorage.productPosition);
    }
    if(window.sessionStorage.actionFieldList){
        $form.find('#productList').val(window.sessionStorage.actionFieldList);
    }
    if(window.sessionStorage.gridViewType){
        $form.find('#viewType').val(window.sessionStorage.gridViewType);
    }
    
    return Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: $form.serialize()
    })).then((response) => {
        // handle error in the response
        if (response.error) {
            throw new Error(response.error);
        } else {
            $cache.document.trigger('product.addedToCart', [$form], $qty.val());
            return response;
        }
    });
};

var addItemToCartQuickShop = function (params) {
    var {pid, cartAction, cid, productPosition, loaderLocation, showMiniCart} = params;
    var productList = '';
    var recommendationsCarouselData = $(loaderLocation).parents('.recommendations-carousel');
    var recommendationsCarouselTitle = recommendationsCarouselData.data("display-title");
    var recentlyViewedDiv = $(loaderLocation).parents('.recently-viewed-carousel');
    var actionFieldList = $(loaderLocation).parents('.js-product-impression').data('productDetails').list;
    if (recentlyViewedDiv.length > 0) {
        productList = recentlyViewedDiv.find('.recently-viewed-carousel__title')[0].innerHTML;
    } else if(recommendationsCarouselData.length > 0 && recommendationsCarouselTitle !== undefined) {
        productList = recommendationsCarouselData.data("display-title");
        let gridTile = $(loaderLocation).parents('.grid-tile');
        productPosition = recommendationsCarouselData.find('.grid-tile').index(gridTile) + 1;
    } else if(pageContext.type.toUpperCase() === 'search'.toUpperCase()) {
        productList = 'Search';
    } else {
        productList = actionFieldList;
    }
    var selectedView = $('.js-cmp-gridView');
    var viewType = 'not apply';     
    if(selectedView.data('cmp') == 'gridView'){
        viewType = selectedView.data('json-config').preSelectedViewMode;
    }
    return new Promise((res, rej) => {
        var url = util.appendParamsToUrl(Urls.addProduct, {pid: pid, cartAction: cartAction, cid:cid, productPosition:productPosition, productList:productList, viewType:viewType});
        ajax({
            type: 'POST',
            url: url,
            contentType: 'text/html',
            callback: (response) => {
                if (response.error) {
                    return rej(response.error);
                }

                minicart.update(response);

                if (window.pageContext && $('.c-mini-cart-content') && $('.c-mini-cart-content').data('context')) {
                    let context = $('.c-mini-cart-content').data('context');
                    window.pageContext.analytics.basket = context.analytics && context.analytics.basket ? context.analytics.basket : '';
                }

                if (!layout.isSmallView() && showMiniCart) {
                    minicart.miniCartToggle(true);
                }
                minicart.loadBonusProducts();
                cart.updateCart(Urls.cartShow, false, false);

                eventMgr.execute('Ometria.add.to.cart', pid)
                    .catch(() => {});
                res();
            }
        });
    });
};

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e) {
    e.preventDefault();
    var $form = $(this).closest('form');

    if($(this).parents('.js-edit-cart').length > 0) {
        window.sessionStorage.gridViewType = 'not apply';
        var prodID = $(this).parents('.js-edit-cart').find('.c-product-details__name').text().trim();
        var $lineItem;
        if($(this).parents('.product-edit-modal').hasClass('cartTriggered')) {
            $lineItem = $('.c-products-table').find('.c-products-item__name a:contains("'+prodID+'")').parents('.c-products-table__item');
            window.sessionStorage.actionFieldList = 'cart';
        } else {
            $lineItem = $('.c-mini-cart__products-container, .c-checkout-minicart__list').find('.c-mini-cart__product-item-name a:contains("'+prodID+'")').parents('.c-mini-cart__product-item-row');
            window.sessionStorage.actionFieldList = 'minibag';
        }
        window.sessionStorage.productPosition = $(this).parents('.js-edit-cart').attr('data-trigger-pos');
        var $currQty = parseInt($('.c-products-table').find('.c-products-item__name a:contains("'+prodID+'")').parents('.c-products-table__item').find('.js-item-quantity-field').val());
        if($(this).parents('.js-edit-cart').attr('data-variation-changed') === 'true') {
            $cache.document.trigger('product.removeFromCart', [$lineItem, $currQty]);
            setTimeout(function() {
                addItemToCartHandler($form);
            }, 400);
        } else {
            page.refresh();
        }
    } else {
        addItemToCartHandler($form);
    }
};

/**
 * @description add item to cart handler
 */
function addItemToCartHandler($form) {
    addItemToCart($form).then((response) => {
        var $uuid = $form.find('input[name="uuid"]');
        if ($uuid.length > 0 && $uuid.val().length > 0) {
            page.refresh();
        } else {
            if(!$(selectors.header).is(':visible')) {
                $(selectors.header).show();
            }            
            minicart.show(response);
            if (window.pageContext && $('.c-mini-cart-content') && $('.c-mini-cart-content').data('context')) {
                let context = $('.c-mini-cart-content').data('context');
                window.pageContext.analytics.basket = context.analytics && context.analytics.basket ? context.analytics.basket : '';
            }
            eventMgr.execute('Ometria.add.to.cart', $form.find('[name=pid]').attr('value'))
                .catch(() => {});
        }
    });
}

/**
 * @description Handler to handle hover event of add to cart button
 */
var addToCartHover = function () {
    var button = $(this),
        originalText = $.trim(button.text()),
        hoverText = button.data('hover-text');

    button.data('hover-text', originalText);
    button.text(hoverText);
    button.toggleClass('active');
};

/**
 * @description select all options sticky handler
 */
function selectAllOptionsSticky() {
    if($(selectors.stickyVariationsBlock).is(':visible')) {
        $(selectors.productVariationsLabelSuggestion).show();
        $(selectors.productVariationsLabelError).show();
    }
    $(selectors.stickyVariationsBlock).addClass('showMobileSticky');
    $(selectors.stickyVariationsBlock).removeClass('hideDesktopSticky');
    $(selectors.productStickyVariationTitle).not('.selected').first().trigger('click');
    if (layout.isMobileView()) {
        $('html, body').animate({
            scrollTop: 0
        }, 600);
    }
}

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();
    var $productForms = $('#product-set-list').find('form').toArray();
    Promise.all(_.map($productForms, addItemToCart))
        .then((responses) => {
            dialog.close();
            // show the final response only, which would include all the other items
            minicart.show(responses[responses.length - 1]);
        });
};

/**
 * @function
 * @description hide sticky product detail section
 */
 function hideProductStikcyDetails() {
    $(selectors.stickyVariationsBlock).removeClass('showMobileSticky');
    $(selectors.productStickyVariationTitle).removeClass('active');
}

/**
 * @function
 * @description show sticky product detail section
 */
 function showDesktopStikcyVariation() {
    $(selectors.stickyVariationsBlock).removeClass('hideDesktopSticky');
    $(selectors.productStickyVariationTitle).removeClass('active');
    $(selectors.stickyVariationsBlock).addClass('showMobileSticky');
    $(this).parents(selectors.productDetailsStickyWrapper).find('li').removeClass('deskShowVariationItem');
    var variationIndex = $(this).attr('data-variation-index');
    var targetVariationType = '.sticky-variation-item-'+variationIndex;
    $(this).parents(selectors.productDetailsStickyWrapper).find(targetVariationType).addClass('deskShowVariationItem');
    $(this).addClass('active');
}

/**
 * @function
 * @description hide sticky product detail section
 */
 function triggerSizeGuideLink() {
    if ($('a.size-guide-link').length > 0) {
        $('a.size-guide-link').trigger('click');
    } else {
        $(selectors.jsSizeGuideLink).trigger('click');
    }
}

/**
 * @function
 * @description show desktop Variation Error Label
 */
function showDesktopVariationLabelError() {
    $(selectors.productVariationsLabelError).show();
}

/**
 * @function
 * @description enable slick slider based on width
 */
var enableColorSlickSilder = function ($swatchParentContainer) {
    var $swatch = $swatchParentContainer.find('.c-product-variations__color-sublist').find('li.c-product-variations__subitem');
    var swatchWidth = $swatch.outerWidth(true);
    var swatchContainerWidth = Math.floor($swatchParentContainer.width());
    var totalSwatchCount = $swatch.length;
    var swatchCountLimit = Math.floor(swatchContainerWidth/swatchWidth);

    return totalSwatchCount > swatchCountLimit;
}

/**
 * @function
 * @description initialize cache
 */
function initializeCache() {
    $cache.document = $(document);
}

/**
 * @function
 * @description initialize events
 */
function initializeEvents() {
    $cache.document.find(selectors.addToCartDisabled).attr('title', $('.availability-msg').text());
    $cache.document.off('click', selectors.addToCart, addToCart).on('click', selectors.addToCart, addToCart);
    $cache.document.on('click', selectors.selectAllOptionsSticky, selectAllOptionsSticky);
    $cache.document.on('click', selectors.addAllToCart, addAllToCart);
    eventMgr.registerAction('addToCart.quick.shop', addItemToCartQuickShop);
    $cache.document.on('click', selectors.productStickyClose, hideProductStikcyDetails);
    $cache.document.on('click', selectors.stickySizeGuideLink, triggerSizeGuideLink);
    $cache.document.on('click', selectors.productStickyVariationTitle, showDesktopStikcyVariation);
    $cache.document.on('click', selectors.pdpMainAddToBagCTA, showDesktopVariationLabelError);}

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
function init() {
    initializeCache();
    initializeEvents();
}

module.exports = {
    init: function (params) {
        init(params);
    }
};
