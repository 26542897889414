import Block from '_core_ext/core/common/Block';
import eventMgr from '_core_ext/core/eventMgr';
import quickShopServices from './utils/quickShopServices';
import layout from '_core_ext/layout';
import slickSlider from '../../../../components/common/SlickSlider';
const LAYOUT_CONFIG_COMPLEX_PRODUCTS = {
    desktop: {
        maxRows: 4,
        maxColumns: 9,
        maxLengthColumns: 4
    },
    mobile: {
        maxRows: 4,
        maxColumns: 6,
        maxLengthColumns: 3
    }
};

export default class QuickShop extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        quickShopServices.init();

        this.animationDuration = 200;
        this.masterProductId = this.config.masterProductId;
        this.selectedVariationAttributes = this.config.selectedVariationAttributes;
        this.selectedProductVariationId = null;
        this.numberOfAttrsRequired = parseInt(this.config.numberOfAttrsRequired, 10);
        this.lastAttrSelected = null;
        this.isComplexProduct = this.config.isComplexProduct;
        this.cid = this.config.cid;
        this.productPosition = this.config.productPosition;

        if (this.isComplexProduct) {
            this.updateLengthAttrProperties();
            this.updateLayout();
        }

        /* eslint-disable */
        this.event('click', '.js-product-variations__subitem.selectable > .js-product-variations__link', this.onSelectableAttributeValueClick.bind(this));
        this.event('click', '.js-product-variations__subitem.unselectable.selected > .js-product-variations__link', this.onSelectableAttributeValueClick.bind(this));
        this.event('click', '.js-product-variations__subitem.unselectable:not(.selected) > .js-product-variations__link', this.onNotifyMeClick.bind(this));
        this.event('click', '.js-product-variations__subitem.disabled > .js-product-variations__link', (el ,event) => event.preventDefault());
        this.event('click', '.js-product-variations__subitem:not(.disabled, .selectable, .selected, .unselectable) > .js-product-variations__link', (el ,event) => event.preventDefault());
        //this.event('click', '.js-hide-quickshop', this.hide.bind(this));
        this.eventMgr('window.modechanged', this.onWindowModeChanged.bind(this));
        /* eslint-enable */
        $('.js-hide-quickshop').on('click', function(e) {
            e.preventDefault();
            var $quickShopWrapper = $(this).closest('.c-product-tile__wrapper').find('.c-quick-shop');
            $quickShopWrapper.hide('slide', {direction: 'down'}, 200);
            $quickShopWrapper.find('.color-swatches').removeClass('d-none');
            $quickShopWrapper.find('.size, .length').addClass('d-none');
            $(this).siblings('.c-quick-shop__bag-icon').removeClass('d-none');
            $(this).addClass('d-none');
        });
    }

    onSelectableAttributeValueClick(el, event) {
        var recommendationCarouselQS = $('.recommendation-carousel');
        //event.stopPropagation();
        event.preventDefault();
        this.updateSelectedVariationAttributes($(el));
        this.updateQuickShop()
            .then(() => this.isVariantValidToAddToCard())
            .then((addToCart) => {
                if (addToCart) {
                    this.addToCart();
                } else {
                    this.$el.find('.length').removeClass('d-none');
                }
            });
    }

    onNotifyMeClick(el, event) {
        event.stopPropagation();
        event.preventDefault();
        if (layout.isMobileView()) {
            this.hide();
        }
        this.updateSelectedVariationAttributes($(el));
        this.notifyMe({
            pid: this.masterProductId,
            selectedVariationAttributes: this.selectedVariationAttributes,
            loaderLocation: this.$el.parent()
        });
    }

    updateSelectedVariationAttributes($variationAttributeValue) {
        var attributeId = $variationAttributeValue.parents('.js-variation-items').data('attr-id');
        var isAttributeSelected = $variationAttributeValue.parent().hasClass('selected');

        if (isAttributeSelected) {
            delete this.selectedVariationAttributes[attributeId];
        } else {
            var attributeValue = $variationAttributeValue.data('attr-value');
            this.setSelectedVariationAttribute(attributeId, attributeValue);
        }
    }

    getQuickShopContent(params) {
        return eventMgr.execute('get.quick.shop.content', params);
    }

    setSelectedVariationAttribute(attributeId, attributeValue) {
        this.selectedVariationAttributes[attributeId] = attributeValue;
        this.lastAttrSelected = attributeId;
    }

    getSelectedVariationAttributes() {
        return this.selectedVariationAttributes;
    }

    clearSelectedVariationAttributes() {
        ['size', 'length'].forEach((attrKey) => delete this.selectedVariationAttributes[attrKey]);
    }

    updateQuickShopContent(html) {
        this.$el.find('.js-quick-shop-content').replaceWith(html);
        if (this.isComplexProduct) {
            this.updateLengthAttrProperties();
            this.updateLayout();
        }
    }

    updateQuickShop() {
        return this.getQuickShopContent({
            ajaxParams: Object.assign({}, this.getSelectedVariationAttributes(), {pid: this.masterProductId}, {cid: this.cid}, {productPosition: this.productPosition}),
            loaderLocation: this.$el.parent()
        }).then((html) => {
            this.updateQuickShopContent(html);
            this.updateSelectedVariantId();
        });
    }

    updateQuickShopColorChange(selectedColorValue) {
        this.setSelectedVariationAttribute('color', selectedColorValue);
        this.resetQuickShop();
    }

    updateSelectedVariantId() {
        this.selectedProductVariationId = this.$el.find('.js-selected-variant-info').data('variation-id');
    }

    addToCart() {
        var pid = this.selectedProductVariationId;
        var $overlay = this.$el.closest('.c-product-tile__wrapper').find('.great-choice-overlay');
        var $quickShop = this.$el;
        $overlay.removeClass('d-none');
        $quickShop.addClass('d-none');
        setTimeout (function() {
            $overlay.addClass('d-none');
            $quickShop.removeClass('d-none');
            $quickShop.css('display','block');
            $quickShop.find('.color-swatches').removeClass('d-none');
            $quickShop.closest('.c-product-tile__wrapper').find('.c-quick-shop__close').addClass('d-none');
            $quickShop.closest('.c-product-tile__wrapper').find('.c-quick-shop__bag').removeClass('d-none');
        }, 2500);
        let productPosition = this.$el.parents('li').index() + 1;
        eventMgr.execute('addToCart.quick.shop', {
            pid: this.selectedProductVariationId,
            cartAction: this.$el.find('.js-selected-variant-info').data('cart-action'),
            cid: this.$el.find('.js-selected-variant-info').data('cid'),
            productPosition: productPosition,
            loaderLocation: this.$el.parent(),
            showMiniCart: true
        }).then(() => {
            return this.resetQuickShop();
        }).then(() => {
            this.emitter.emit('quickShop.product.added.to.cart', {pid: pid});
        }).then(() => this.hide());
    }

    isVariantValidToAddToCard() {
        return !!this.selectedProductVariationId && !!this.$el.find('.js-selected-variant-info').data('valid-to-add-to-cart');
    }

    toggle(hide) {
        if (hide) {
            this.hide();
        } else {
            this.show();
        }
    }

    show() {
        this.$el.show('slide', {direction: 'down'}, this.animationDuration);
    }

    hide() {
        this.$el.hide('slide', {direction: 'down'}, this.animationDuration);
    }

    notifyMe(params) {
        this.emitter.emit('quickShop.notify.me', params);
    }

    resetQuickShop() {
        this.clearSelectedVariationAttributes();
        this.updateQuickShop();
    }

    onNotifyMeDialogClose() {
        var numberOfAttrsSelected = Object.keys(this.selectedVariationAttributes).length;
        var updateQuickShop = this.numberOfAttrsRequired !== numberOfAttrsSelected;

        if (updateQuickShop) {
            this.updateQuickShop()
                .then(() => {
                    this.show();
                    if (!layout.isMobileView()) {
                        this.$el.find('.color-swatches').addClass('d-none');
                    }
                    var lastAttrSelected = this.lastAttrSelected.trim().toLowerCase();
                    this.$el.find('ul li').removeClass('selected');
                });
        } else {
            this.show();
            if (this.lastAttrSelected in this.selectedVariationAttributes) {
                delete this.selectedVariationAttributes[this.lastAttrSelected];
            }
        }
    }

    getLayOutConfig() {
        var viewMode = layout.isExtraLargeView() ? 'desktop' : 'mobile';
        return LAYOUT_CONFIG_COMPLEX_PRODUCTS[viewMode];
    }

    getColumnsForLayOut() {
        var {maxRows, maxColumns, maxLengthColumns} = this.getLayOutConfig();
        var maxAmountOfAttrValues = maxRows * maxLengthColumns;
        var numberOfLengthColumns;
        var numberOfSizeColumns;

        /** When this.hasLengthComplexAttrValues is true is because a complex product has one or more
         * length attributes values that have more than 3 characters. In such a case is necessary to set
         * the numberOfLengthColumns to the maximum amount allowed in order to provide enough space so
         * that the length attr values are displayed properly
        */
        if (this.amountLengthAttrsValues > maxAmountOfAttrValues || this.hasLengthComplexAttrValues) {
            numberOfLengthColumns = maxLengthColumns;
        } else {
            numberOfLengthColumns = Math.ceil(this.amountLengthAttrsValues / maxRows);
        }

        numberOfSizeColumns = maxColumns - numberOfLengthColumns;

        return {
            size: numberOfSizeColumns,
            length: numberOfLengthColumns
        };
    }

    updateLayout() {
        var columnsForLayOut = this.getColumnsForLayOut();
        var className;

        for (var attrId in columnsForLayOut) {
            var numberOfcolumns = columnsForLayOut[attrId];

            if (attrId === 'length') {
                className = `number-of-columns--is-${this.hasLengthComplexAttrValues ? 'text' : numberOfcolumns}`;
            } else {
                className = `number-of-columns--is-${numberOfcolumns}`;
            }
            this.$el.find(`[data-attr-id=${attrId}]`)
                .removeClass((i, className) => (className.match(/number-of-columns--is-(\d{1}|text)(?!-*\w)/g) || []).join(' '))
                .addClass(className);
        }
    }

    onWindowModeChanged() {
        if (!this.isComplexProduct) {
            return;
        }
        this.updateLayout();
    }

    updateLengthAttrProperties() {
        var lengthAttrProperties = this.$el.find('.js-length-attr-properties').data('length-properties');
        /*The hasLengthComplexAttrValues property is true when a complex product has one or more length attributes
        values that have more than 3 characters.*/
        this.hasLengthComplexAttrValues = lengthAttrProperties.hasComplexAttrsValues;
        this.amountLengthAttrsValues = lengthAttrProperties.amountAttrsValues;
    }
}
