import AccessPointShippingForm from '_core_ext/components/checkout/shipping/shippingForms/AccessPoint';
export default class SendingShippingForm extends AccessPointShippingForm {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
    }

    findPickUpLocations (shippingMethodId) {
        var $locatorForm = this.locatorForm.$el;
        var zipCode = $locatorForm.find('.js-get-zipcode-suggestions').val();
        var formData = $locatorForm.serializeArray();
        var action = $locatorForm.find('.js-find-location').attr('name');
        if (!zipCode) {
            return;
        }
        this.handleGetLocationsPicker(action, formData, null, shippingMethodId);
    }
}