import Block from '_core_ext/core/common/Block';
import util from '_core_ext/util';
import eventMgr from '_core_ext/core/eventMgr';


export default class ShippingFormsContainer extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.enabledShippingForm = this.items[0];
        this.subscribeToChildrenEvents();
    }

    subscribeToChildrenEvents () {
        this.enabledShippingForm.on('shippingForm.on.delivery.form.submit', this.handleOnDeliveryFormSubmit.bind(this));
        this.enabledShippingForm.on('shippingForm.on.change.country.click', this.handleOnCountryChangeClick.bind(this));
        /* eslint-disable */
        switch (this.enabledShippingForm.deliveryOption) {
            case 'HOME':
                this.onChild('homeShippingForm', 'homeShippingForm.address.selected', this.handleHomeAddressSelection.bind(this));
                this.onChild('homeShippingForm', 'homeShippingForm.add.new.address', this.handleHomeOnAddNewAddress.bind(this));
                this.onChild('homeShippingForm', 'homeShippingForm.edited.address.save', this.handleSaveEditedAddress.bind(this));
                break;
            case 'UPS_ACCESS_POINT':
                this.onChild('upsShippingForm', 'accessPointShippingForm.access.point.selected', this.handleAccessPointSelected.bind(this));
                this.onChild('upsShippingForm', 'pickUpBaseShippingForm.change.pick.up.location', this.handleChangePickUpLocation.bind(this));
                this.onChild('upsShippingForm', 'accessPointShippingForm.find.pick.up.locations.current.location', this.handleFindPickUpLocationsCurrentLocation.bind(this));
                this.onChild('upsShippingForm', 'accessPointShippingForm.find.pick.up.locations', this.handleFindPickUpLocations.bind(this));
                break;
            case 'CX_ACCESS_POINT':
                this.onChild('correosExpressShippingForm', 'accessPointShippingForm.access.point.selected', this.handleAccessPointSelected.bind(this));
                this.onChild('correosExpressShippingForm', 'pickUpBaseShippingForm.change.pick.up.location', this.handleChangePickUpLocation.bind(this));
                this.onChild('correosExpressShippingForm', 'accessPointShippingForm.find.pick.up.locations', this.handleFindPickUpLocations.bind(this));
                break;
            case 'IN_STORE':
                this.onChild('inStoreShippingForm', 'inStoreShippingForm.store.selected', this.handleStoreSelected.bind(this));
                this.onChild('inStoreShippingForm', 'pickUpBaseShippingForm.change.pick.up.location', this.handleChangePickUpLocation.bind(this));
                break;
            case 'SENDING_ACCESS_POINT':
                this.onChild('sendingShippingForm', 'accessPointShippingForm.access.point.selected', this.handleAccessPointSelected.bind(this));
                this.onChild('sendingShippingForm', 'pickUpBaseShippingForm.change.pick.up.location', this.handleChangePickUpLocation.bind(this));
                this.onChild('sendingShippingForm', 'accessPointShippingForm.find.pick.up.locations', this.handleFindPickUpLocations.bind(this));
                break;
        }
        /* eslint-enable */
    }

    changeHeader(shippingMethodInfo, regionInfo, shipByRegionEnabled) {
        var {regionName} = regionInfo;
        var {shippingMethodName, shippingMethodDescription} = shippingMethodInfo;
        var header = shipByRegionEnabled ? regionName + ' > ' + shippingMethodName : shippingMethodName;
        this.$el.find('.js-shippingFormHeader').text(header);
        this.$el.find('.js-shippingFormshippingMethodDescription').text(shippingMethodDescription);
    }

    handleChangeHeader (shippingMethodInfo, regionInfo, shipByRegionEnabled) {
        this.changeHeader(shippingMethodInfo, regionInfo, shipByRegionEnabled);
        this.scrollToForm();
    }

    scrollToForm () {
        var $selectedShippingMethodInfo = this.$el.find('.js-selected-shipping-method-info');
        util.scrollTo($selectedShippingMethodInfo.offset().top, 600);
    }

    setEnabledShippingForm (shippingForm) {
        this.enabledShippingForm = shippingForm;
    }

    handleOnDeliveryFormSubmit (deliveryAddressInfo, deliveryOption) {
        this.emitter.emit('shippingFormsContainer.on.delivery.form.submit', deliveryAddressInfo, deliveryOption);
    }

    handleOnCountryChangeClick () {
        var countrySelectorDialog = document.querySelector('.js-checkout-country_selector');
        if (countrySelectorDialog) {
            countrySelectorDialog.click();
        }
    }

    submitDeliveryForm (shippingMethodId) {
        var $deliveryForm = this.enabledShippingForm.deliveryForm.$el;
        var formData = $deliveryForm.serializeArray();
        var action = $deliveryForm.find('.js-checkout__button').attr('name');
        eventMgr.execute('submit.delivery.form', {
            formData: formData,
            action: action,
            shippingMethodId: shippingMethodId,
            formEl: $deliveryForm
        });
    }

    getDeliveryAddressInfo () {
        return this.enabledShippingForm.getDeliveryAddressInfo();
    }

    getShopperInfo () {
        return this.enabledShippingForm.getShopperInfo();
    }

    isDeliveryFormActive () {
        return this.enabledShippingForm.isDeliveryFormActive();
    }

    showErrorMessage () {
        this.enabledShippingForm.showErrorMessage();
    }

    clearErrorMessage () {
        this.enabledShippingForm.clearErrorMessage();
    }

    handleHomeAddressSelection (deliveryAddressInfo, selectedStoredAddessId) {
        this.emitter.emit('shippingFormsContainer.on.home.address.selection', deliveryAddressInfo, selectedStoredAddessId);
    }

    isDeliveryFormValid () {
        return this.enabledShippingForm.isDeliveryFormValid();
    }

    getSelectedStoreId () {
        var storeId = '';
        this.getById('inStoreShippingForm', (inStoreShippingForm) => {
            storeId = inStoreShippingForm.getSelectedStoreId();
        });

        return storeId;
    }

    getShippingForm (deliveryOption) {
        return eventMgr.execute('get.shipping.form', {
            deliveryOption: deliveryOption
        });
    }

    changeShippingForm (shippingForm) {
        this.$el.find('.js-selected-shipping-method-form-wrapper').empty().html(shippingForm);
        var instoreShippingMethod = $('.js-shipping-method__item-IN-STORE').find('.c-shipping-method__item-heading span').text();
        var orderAvailbilityText = $('.js-shipping-method__item-IN-STORE').find('.mobile-item-info .c-shipping-method__subitem').text();
        $('.selected-in-store').text(instoreShippingMethod);
        $('.c-delivery-stores__terms').html('Your order will be available <b>for '+ orderAvailbilityText + '</b>');
        return eventMgr.execute('components.update', this.$el); 
    }

    handleShippingFormChange(shippingMethodInfo, regionInfo, shipByRegionEnabled) {
        var {shippingMethodDeliveryOption} = shippingMethodInfo;
        return this.getShippingForm(shippingMethodDeliveryOption)
            .then((shippingForm) => {
                return this.changeShippingForm(shippingForm);
            })
            .then(() => {
                this.setEnabledShippingForm(this.items[0]);
                this.subscribeToChildrenEvents();
                this.handleChangeHeader(shippingMethodInfo, regionInfo, shipByRegionEnabled);
                if (shippingMethodDeliveryOption === 'HOME') {
                    $(document).trigger('address.init', ['address']);
                }
            });
    }

    handleHomeOnAddNewAddress () {
        this.emitter.emit('shippingFormsContainer.on.home.add.new.address');
    }

    handleSaveEditedAddress (newDeliveryAddressInfo, editedAddressId) {
        this.emitter.emit('shippingFormsContainer.on.home.edited.address.save',
            newDeliveryAddressInfo, editedAddressId);
    }

    handleAccessPointSelected (deliveryOption, accessPointData) {
        this.emitter.emit('shippingFormsContainer.on.access.point.selected', deliveryOption, accessPointData);
    }

    handleStoreSelected (deliveryOption, storeData) {
        this.emitter.emit('shippingFormsContainer.on.store.selected', deliveryOption, storeData);
    }

    handleChangePickUpLocation () {
        this.emitter.emit('shippingFormsContainer.on.change.pick.up.location');
    }

    handleFindPickUpLocations () {
        this.emitter.emit('shippingFormsContainer.on.find.pick.up.locations');
    }

    handleFindPickUpLocationsCurrentLocation () {
        this.emitter.emit('shippingFormsContainer.on.find.pick.up.locations.current.location');
    }

    setStoredHomeAddress (addressId) {
        this.enabledShippingForm.setStoredHomeAddress(addressId);
    }

    updateDeliveryForm (deliveryAddressesFormData, shopperInfo, isCustomerLogged) {
        this.enabledShippingForm.updateDeliveryForm(deliveryAddressesFormData, shopperInfo, isCustomerLogged);
    }

    findPickUpLocations (shippingMethodId) {
        this.enabledShippingForm.findPickUpLocations(shippingMethodId);
    }

    findPickUpLocationsCurrentLocation (shippingMethodId) {
        this.enabledShippingForm.findPickUpLocationsOncurrentlocation(shippingMethodId);
    }

    getDeliveryAddressesData () {
        return this.enabledShippingForm.getDeliveryAddressesData();
    }

    beforeSubmitCheck () {
        return this.enabledShippingForm.beforeSubmitCheck();
    }
}