import Component from '_core_ext/core/Component';
import util from '_core_ext/util';

export default class CustomAccordion extends Component {
    init() {
        this.settings = {
            header: '> .js-global-accordion-item > .js-global-accordion-header',
            heightStyle: 'content',
            active: true,
            collapsible: true,
            disabled: true
        };

        this.initConfig(this.settings);
        if (this.eligibleOnDevice()) {
            if (!this.referencesLoaded) {
                this.getReferences();
            }

            this.initializeCache();
            this.initializeEvents();
            this.initAccordion();
            if (!this.config.staticContent) {
                this.constructAccordionURL();
            }
        }
    }

    initAccordion() {

        if (!this.$el.find(this.config.header).length) {
            return;
        }

        if (this.cache.$accordionActiveItem.length) {
            this.expandParentAccordions();
        } else {
            this.$el.accordion(this.config);
        }
    }

    expandParentAccordions() {
        var accordionObjects = [],
            $parentAccordionItems = this.cache.$accordionActiveItem.parents('.js-global-accordion-item');

        $parentAccordionItems.each((index, item) => {
            accordionObjects.push({
                $element: $(item).parent(),
                indexToExpand: $(item).data('index')
            });
        });

        this.setAccordionsData(accordionObjects);
    }

    setAccordionsData(accordionObjects) {
        for (var i = 0; i < accordionObjects.length; i++) {
            var parentAccordionItem = accordionObjects[i];
            parentAccordionItem.$element.data('active', parentAccordionItem.indexToExpand);
            parentAccordionItem.$element.accordion($.extend(true, {}, this.config, {
                active: parentAccordionItem.indexToExpand || 0
            }));
        }
    }

    updateAccordion() {
        if (this.eligibleOnDevice()) {
            if (!this.$el.data('ui-accordion')) {
                this.initAccordion();
            }
        } else {
            if (this.$el.data('ui-accordion')) {
                this.$el.accordion('destroy');
            }
        }
    }

    onAccordionOpen(event, index) {
        this.$el.accordion('option', 'active', index);
    }

    onFormError() {
        this.$el.accordion({
            active: 0
        });
    }

    initializeEvents() {
        this.eventMgr('window.resize', this.updateAccordion);
        this.eventMgr('window.modechanged', this.updateAccordion);
        this.$el.on('accordion.open', this.onAccordionOpen.bind(this));
        this.eventMgr('form.showError', this.onFormError);
        this.eventMgr('productVariation.changed', this.updateAccordion);
        this.cache.$accordionOpenBtn.on('click', this.addActiveClass.bind(this));
        this.cache.$document.trigger('accordion.initialized');
    }

    initializeCache() {
        this.cache = {};
        this.cache.$document = $(document);
        this.cache.$accordionActiveItem = this.$el.find('.js-active-accordion');
        this.cache.$accordionHeading = this.$el.find('.js-global-accordion-header');
        this.cache.$accordionContent = this.$el.find('.o-accordion__content');
        // console.log(this.cache.$accordionContent);
        if (this.$el.find('.js-custom-accordion-open').length > 0)
            this.cache.$accordionOpenBtn = this.$el.find('.js-custom-accordion-open');
        else
            this.cache.$accordionOpenBtn = this.$el.siblings('.js-custom-accordion-open');

        if (this.config.staticContent) {
            const accHead = this.cache.$accordionHeading;
            this.cache.$accordionContent.find('.js-cancel-button').click(function () {
                accHead.trigger('accordion.open', 1);
                accHead.parent('.js-global-accordion-item').removeClass('active');
            });
        }
    }

    constructAccordionURL() {
        const url = this.cache.$accordionOpenBtn.data('url');
        if (url && url != '') {
            this.ajaxUrl = util.appendParamToURL(url, 'format', 'ajax');

            let jsonConfig = this.cache.$accordionOpenBtn.data('jsonConfig');
            if (jsonConfig && jsonConfig.URLParams) {
                this.ajaxUrl = util.appendParamsToUrl(this.ajaxUrl, jsonConfig.URLParams);
            }
        }
    }

    getReferences() {
        if (!('ui' in $)) {
            require('jquery-ui-dist/jquery-ui');
        }

        if (!('widgets' in $.ui)) {
            require('jquery-ui/ui/widget');
        }
        if (!('unique-id' in $.ui)) {
            require('jquery-ui/ui/unique-id');
        }
        if (!('keyCode' in $.ui)) {
            require('jquery-ui/ui/keycode');
        }
        if (!('accordion' in $.ui)) {
            require('jquery-ui/ui/widgets/accordion');
        }

        this.referencesLoaded = true;
    }

    addActiveClass () {
        if (this.ajaxUrl) {
            var _this = this;
            $.ajax({
                url: _this.ajaxUrl,
                type: 'get',
                dataType: 'html',
                success: function(response) {
                    _this.cache.$accordionContent.empty().html(response);
                    _this.cache.$accordionHeading.trigger('accordion.open', 0);
                    _this.cache.$accordionHeading.parent('.js-global-accordion-item').addClass('active');
                    const accHead = _this.cache.$accordionHeading;
                    _this.cache.$accordionContent.find('.js-cancel-button').click(function () {
                        accHead.trigger('accordion.open', 1);
                        accHead.parent('.js-global-accordion-item').removeClass('active');
                    });
                },
                error: function() {}
            });
        } else {
            this.cache.$accordionHeading.trigger('accordion.open', 0);
            this.cache.$accordionHeading.parent('.js-global-accordion-item').addClass('active');
        }
    }
}
