'use strict';
/* eslint-disable */
var cartStoreInventory = require('_core/storeinventory/cart'),
    progress = require('_core/progress'),
    page = require('_core_ext/page'),
    util = require('_core_ext/util'),
    ajax = require('_core_ext/ajax'),
    $minicart = $('.js-minicart'),
    layout = require('_core_ext/layout'),
    $cart = $('.js-cart'),
    timeToWait = 200,
    selectors = {
        emptyCartRecommendations: '.js-cart-recommendations'
    },
    qtyEvent='',
    removedQty=0,
    $cache = {
        document: $(document),
        window: $(window)
    };

import eventMgr from '_core_ext/core/eventMgr';

function updateCart (url, showMiniBag=true, cartContentUpdate=true) {
    var pageName = page.ns;
    var $updates;

    url = util.appendParamToURL(url, 'viewType', pageName);

    if ($cart.length) {
        progress.show($cart.find('.js-cart-form'));
    }

    var miniCartBodyStyles = '';
    if ($('.c-mini-cart__body').length > 0) {
        miniCartBodyStyles = $('.c-mini-cart__body').attr('style');
    }

    ajax({
        url: url,
        callback: function (data) {
            $updates = $(data);

            if (data && $cart.length) {
                if (!cartContentUpdate && $("#cart-empty").length === 0) { // If cart is non-empty then update only cart line-items and price details. Excluding slots.
                    $cart.find('.c-cart__wrapper').html($updates.find('.c-cart__wrapper').html());
                } else {
                    $cart.empty();
                    $cart.append($updates.find('.js-cart-table-wrapper'));

                    if ($cache.document.find(selectors.emptyCartRecommendations).length > 0) {
                        $cache.document.trigger('cart.updated', selectors.emptyCartRecommendations);
                    }
                    progress.hide();
                }

                if (window.pageContext && $('.js-cart-table-wrapper').data('context')) {
                    let context = $('.js-cart-table-wrapper').data('context');
                    window.pageContext.analytics.basket = context.analytics && context.analytics.basket ? context.analytics.basket : '';
                }
                // Display express checkout heading if express checkout available in cart.
                if ($cart.find('.paypal-cart-buttons-container').length > 0 || $cart.find('.dw-apple-pay-button').length > 0) {
                    $cart.find('div.cart-payment-heading').removeClass('d-none');
                }
            }

            if (data && $minicart.length && showMiniBag) {
                $updates = $updates.hasClass('js-minicart') ? $updates : $updates.find('.js-minicart').children();
                $minicart.empty();
                $minicart.append($updates.filter(function(index, node){
                    return node.nodeName === "DIV"
                }));
                if (layout.isMobile() || layout.isTablet()) {
                    $('.js-item-quantity-field').prop('disabled', true);
                }

                if (miniCartBodyStyles != '') {
                    $('.c-mini-cart__body').attr('style', miniCartBodyStyles);
                }

                eventMgr.execute('Ometria.cart.changed')
                    .catch(() => {});

                if (window.pageContext && $('.js-mini-cart-content').data('context')) {
                    let context = $('.js-mini-cart-content').data('context');
                    window.pageContext.analytics.basket = context.analytics && context.analytics.basket ? context.analytics.basket : '';
                    if(window.pageContext.analytics.basket && window.pageContext.analytics.basket.ecommerce.length > 0){
                        window.sessionStorage.ecommerce = JSON.stringify(window.pageContext.analytics.basket.ecommerce);
                    }
                }
                // Display express checkout heading if express checkout available in minicart.
                if ($updates.find('.paypal-cart-buttons-container').length > 0 || $updates.find('.dw-apple-pay-button').length > 0) {
                    $updates.find('div.mini-cart-payment-heading').removeClass('d-none');
                }
            }
            if (data && $minicart.length){
                if (window.pageContext && $('.js-mini-cart-content').data('context')) {
                    let context = $('.js-mini-cart-content').data('context');
                    window.pageContext.analytics.basket = context.analytics && context.analytics.basket ? context.analytics.basket : '';
                    if(window.pageContext.analytics.basket && window.pageContext.analytics.basket.ecommerce.length > 0){
                        window.sessionStorage.ecommerce = JSON.stringify(window.pageContext.analytics.basket.ecommerce);
                    }
                }
            }
        }
    });
}

function updateQty (el) {
    var $button = $(el),
        allowedQty = parseInt($button.data('qty')),
        $quantityField = $button.siblings('.js-item-quantity-field'),
        count = parseInt($quantityField.val()),
        updatedCount;

    if ($button.hasClass('js-plus')) {
        updatedCount = count + 1;

        if (updatedCount >= allowedQty) {
            updatedCount = allowedQty;
        }
        if ($button.parents('.c-mini-cart__product-item-row').length > 0) {
            this.qtyEvent = "added";
        }
    } else if ($button.hasClass('js-minus')) {
        updatedCount = count - 1;

        if (updatedCount <= allowedQty) {
            updatedCount = allowedQty;
        }
        if ($button.parents('.c-mini-cart__product-item-row').length > 0) {
            this.qtyEvent = "removed";
        }
    }

    if (updatedCount === count) {
        return false;
    }

    $quantityField.val(updatedCount);
    $quantityField.trigger('change');
}

function updateQtyOnInput (el, isManualZeroInput, originalQTY) {
    var $quantityField = $(el),
        $lineItem = $quantityField.closest('.js-line-item-row'),
        minValue = parseInt($quantityField.siblings('.js-minus').data('qty')),
        maxValue = parseInt($quantityField.siblings('.js-plus').data('qty')),
        selectedQuantity = parseInt($quantityField.val()),
        isNotNumberVal = isNaN(selectedQuantity),
        originalQTY = (originalQTY != undefined && originalQTY != 0 ) ? originalQTY : parseInt($quantityField.data('value')),
        changedQTY,
        url;

    selectedQuantity = isNotNumberVal ? 1 : +selectedQuantity;
    changedQTY = selectedQuantity - originalQTY;
    
    if (isManualZeroInput) {
        if ($quantityField.parents('.c-mini-cart__product-item-row').length > 0) {
            this.removedQty = changedQTY;
        }
        $lineItem.find('.js-item-remove').trigger('click');
        return;
    }

    if (selectedQuantity >= maxValue) {
        selectedQuantity = maxValue;
    }

    if (selectedQuantity <= minValue) {
        selectedQuantity = minValue;
    }

    $quantityField.val(selectedQuantity);
    var position, list, cgid;
    if($(el).parents('.c-products-table').length > 0) {
        position = $('.c-products-table .c-products-table__item').index($(el).parents('.c-products-table__item')) + 1;
        list = 'cart';
        cgid = $(el).parents('#products-item').data('productDetails').cgid;
    } else if($(el).parents('.c-mini-cart__products').length > 0) {
        position = $('.c-mini-cart__products .js-line-item-row').index($(el).parents('.js-line-item-row')) + 1;
        list = 'minibag';
        cgid = $(el).parents('.c-mini-cart__product-item-row').data('productDetails').cgid;
    }
    
    
    url = util.appendParamsToUrl(Urls.updateProductQuantity, {
        uuid: $lineItem.data('uuid'),
        pid: $lineItem.data('pid'),
        Quantity: selectedQuantity,
        position: position,
        list: list,
        cgid: cgid
    });

    if (changedQTY > 0) {
        $cache.document.trigger('product.addedToCart', [$lineItem, changedQTY]);
    } else {
        $cache.document.trigger('product.removeFromCart', [$lineItem, Math.abs(changedQTY)]);
    }

    if (this && this.qtyEvent && this.qtyEvent != '') {
        this.qtyEvent = '';
    }

    updateCart(url);
}

function submitForm (el, isCoupon) {
    var action = $(el).attr('name'),
        data = action + '=' + action,
        url = util.appendParamsToUrl(Urls.submitCartForm, {
            format: 'ajax',
            checkoutStep: '1'
        }),
        $updates;

    if (isCoupon) {
        data += '&' + $cart.find('.js-cart-summary').serialize();
    }

    ajax({
        url: url,
        type: 'POST',
        data: data,
        callback: function (data) {
            $updates = $(data);

            var isCouponAdded = $updates.find('.js-item-remove.js-coupon').length > 0;
            if (isCouponAdded) {
                $cache.document.trigger('coupon.added', [data]);
            }

            if (data && $cart.length) {
                $cart.find('.js-cart-table-wrapper')
                    .replaceWith($updates.find('.js-cart-table-wrapper'));

                if ($cache.document.find(selectors.emptyCartRecommendations).length > 0) {
                    $cache.document.trigger('cart.updated', selectors.emptyCartRecommendations);
                }
                progress.hide();

                if (window.pageContext && $('.js-cart-table-wrapper').data('context')) {
                    let context = $('.js-cart-table-wrapper').data('context');
                    window.pageContext.analytics.basket = context.analytics && context.analytics.basket ? context.analytics.basket : '';
                }
            }

            if (data && $minicart.length) {
                $minicart.empty()
                    .append($updates.find('.js-minicart').children());

                eventMgr.execute('Ometria.cart.changed')
                    .catch(() => {});

                if (window.pageContext && $('.js-mini-cart-content').data('context')) {
                    let context = $('.js-mini-cart-content').data('context');
                    window.pageContext.analytics.basket = context.analytics && context.analytics.basket ? context.analytics.basket : '';
                }
            }
        }
    });

    var $lineItem = $(el).parents('[data-product-details]');
    $lineItem.length > 0 && $cache.document.trigger('product.removeFromCart', [$lineItem,$lineItem.find('input.js-item-quantity-field').val()]);
}

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    $cart
        .on('click', '.js-qty', function (e) {
            e.preventDefault();
            updateQty(this);
        })
        .on('click', '.js-item-remove', function (e) {
            e.preventDefault();
            var $this = $(this);
            var isCouponItem = $this.hasClass('js-coupon');
            let $lineItem = $this.closest('.c-products-table__item'),
                removeItem = true,
                $undoLink = $lineItem.find('.product-undo-link');
            $lineItem.find('.product-undo').addClass('active');
            $undoLink.on('click', function () {
                removeItem = false;
                $lineItem.find('.product-undo').removeClass('active');
            });

            if (isCouponItem) {
                progress.show($cart.find('.js-cart-summary'));
            }

            setTimeout(function() {
                if(removeItem) {
                    submitForm($this, isCouponItem);
                }
            }, 3000);
        })
        .on('change', '.js-item-quantity-field', util.debounce(function () {
            updateQtyOnInput(this, (parseInt($(this).val()) === 0));
        }, timeToWait))
        .on('keydown', '.js-item-quantity-field', function (e) {
            if (e.which === 13) {
                return false;
            }
        })
        .on('click', '.js-toggle-coupon-field', function () {
            $(this).toggleClass('active');
            $cart.find('.js-coupon-field').toggleClass('is--hidden');
            $cache.document.trigger('promocode.show');
        })
        .on('keyup', 'input[name$="_couponCode"]', function (e) {
            e.preventDefault();
            var $couponCode = $cart.find('input[name$="_couponCode"]');
            var $couponCodeCTA = $('.js-add-coupon');
            $couponCode.val() === '' ? $couponCodeCTA.prop('disabled', true) : $couponCodeCTA.prop('disabled', false);
        })
        .on('click', '.js-add-coupon', function (e) {
            e.preventDefault();

            var $couponCode = $cart.find('input[name$="_couponCode"]');
            var $summary = $cart.find('.js-cart-summary');
            var $error = $cart.find('.js-coupon-error');
            var $couponField = $cart.find('.js-coupon-field');
            var code = $couponCode.val();

            if (code.length === 0) {
                $error.html(Resources.COUPON_CODE_MISSING);
                $couponField.addClass('error');
                return;
            }

            progress.show($summary);
            submitForm(this, true);
        })
        .on('keydown', 'form input[name$="_couponCode"]', function (e) {
            if (e.which === 13 && $(this).val().length === 0) {
                return false;
            }
        });
}

var cart = {
    init: function () {
        initializeEvents();
        // Hide express checkout heading if express checkout is not available in cart.
        setTimeout(function() {
            if ($('.paypal-cart-buttons-container').length > 0 || $('.dw-apple-pay-cart').length > 0) {
                $('div.cart-payment-heading').removeClass('d-none');
            }
        }, 1000);

        if (SitePreferences.STORE_PICKUP) {
            cartStoreInventory.init();
        }
    },
    updateQty: updateQty,
    updateQtyOnInput: updateQtyOnInput,
    updateCart: updateCart,
    qtyEvent: qtyEvent,
    removedQty:removedQty
};

module.exports = cart;
