import Block from '_core_ext/core/common/Block';
import layout from '_core_ext/layout';

export default class RefinementsContainer extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.$html = $(document.documentElement);

        this.const = {
            APPLY_IMMEDIATELY: 'immediate',
            PROCEED_IN_QUEUE: 'queue',
            REF_NAME_PREFIX: window.Constants.REFINE_NAME_PARAMETER_PREFIX,
            REF_VALUE_PREFIX: window.Constants.REFINE_VALUE_PARAMETER_PREFIX,
        };

        this.applyRefinementMode = this.const.APPLY_IMMEDIATELY;
        this.wereRefinementsApplied = this.config.wereRefinementsApplied;
        this.onWindowModeChanged({mode: layout.getMode()});

        this.refinementsQueue = {
            attribute: {},
            category: {},
            price: {}
        };

        this.subscribeToChildrenEvents();
        this.event('click', '.js-clear-filters', this.onClearRefinements.bind(this));
        this.event('click', '.js-clear-filter', this.onClearRefinement.bind(this));
        this.event('click', '.js-refinements-apply', this.onApplyRefinements.bind(this));
        this.event('click', '.js-close-filters, .js-filters-overlay', this.onFiltersClose.bind(this));
        this.event('click', '.js-show-filters', this.onFiltersShow.bind(this));
        this.eventMgr('window.modechanged', this.onWindowModeChanged.bind(this));
    }

    onWindowModeChanged(modeData) {
        if (!modeData) {
            return;
        }

        var {mode} = modeData;
        var filtersDropDownExpanded = this.$el.find('.js-filters-dropdown').hasClass('is--expanded');
        if (mode === 'medium' || mode === 'small') {
            if (SitePreferences.ATTRAQT_ENABLED) {
                this.applyRefinementMode = this.const.APPLY_IMMEDIATELY;
            } else {
                this.applyRefinementMode = this.const.PROCEED_IN_QUEUE;
            }

            if (filtersDropDownExpanded) {
                this.$html.addClass('is--filters-open');
            }
        } else {
            this.applyRefinementMode = this.const.APPLY_IMMEDIATELY;
            $('.js-global-dropdown').trigger('dropdown.close');
            this.$html.removeClass('is--filters-open');
        }
    }

    updateChildrenReferences() {
        this.items.forEach((refinement) => {
            this[refinement.id] = refinement;
        });
    }

    subscribeToChildrenEvents() {
        this.items.forEach((refinement) => {
            switch (refinement.getType()) {
                case 'attribute':
                    this.refinementsQueue[refinement.getType()][refinement.id] = refinement.getSelectedValues();
                    break;
                default:
                    this.refinementsQueue[refinement.getType()] = refinement.getSelectedValues();
                    break;
            }
            this.onChild(`${refinement.id}`, `${refinement.id}.refinement.selected`, this.onRefinementChange.bind(this));
            this.onChild(`${refinement.id}`, `${refinement.id}.refinement.display.change`,
                this.onRefinementDisplayChange.bind(this));
        });
    }

    onRefinementChange(refinementsParams) {
        var {searchQueryUrl, refinementId, refinementData} = refinementsParams;
        if (this.applyRefinementMode === this.const.APPLY_IMMEDIATELY) {
            if (SitePreferences.ATTRAQT_ENABLED && this.$html.hasClass('is--filters-open')) {
                $('.js-close-filters').trigger('click');
            }   
            this.emitter.emit(`${this.id}.refinement.selected`, {
                url: searchQueryUrl
            });
        } else {
            // Update refinements queue
            if (this.refinementsQueue[refinementData.type]) {
                switch (refinementData.type) {
                    case 'attribute':
                        this.refinementsQueue[refinementData.type][refinementId] = refinementData.selectedValues;
                        break;
                    default:
                        this.refinementsQueue[refinementData.type] = refinementData.selectedValues;
                        break;
                }
            }
        }
        this.toggleClearFiltersBtn();
    }

    onApplyRefinements(el, event) {
        event.preventDefault();
        if (!this.isAnyRefinementValueSelected()) {
            return;
        }
        var searchRedirectUrl = this.config.searchRedirectUrl;

        this.$el.find('.js-filters-dropdown').trigger('dropdown.close');
        this.$html.removeClass('is--filters-open');

        this.emitter.emit(`${this.id}.refinement.applied`, {
            url: searchRedirectUrl,
            data: {refinementsData: JSON.stringify(this.refinementsQueue)}
        });
    }

    onClearRefinements(el, event) {
        event.preventDefault();

        if (!this.wereRefinementsApplied) {
            this.clearRefinementsSelection();
            this.$el.find('.js-clear-filters').removeClass('active');
            return;
        }

        var searchQueryUrl = $(el).attr('href');

        $(el).closest('.js-dropdown').trigger('dropdown.close');
        this.$html.removeClass('is--filters-open');

        this.emitter.emit(`${this.id}.refinement.selected`, {
            url: searchQueryUrl
        });
    }
    onClearRefinement(el, event) {
        event.preventDefault();

        var searchQueryUrl = window.location.href;
        if ($(el).hasClass('individual-filter')) {
            var refinementId = $(el).parent().next().val();
            if (searchQueryUrl.indexOf('&removeRefinementAttr=') > -1) {
                searchQueryUrl = this.removeParam('removeRefinementAttr', searchQueryUrl, false);
            }
            searchQueryUrl = this.removeParam(refinementId, searchQueryUrl, true);
            searchQueryUrl = searchQueryUrl+'&removeRefinementAttr='+refinementId;
        }

        $(el).closest('.js-dropdown').trigger('dropdown.close');
        this.$html.removeClass('is--filters-open');

        this.emitter.emit(`${this.id}.refinement.selected`, {
            url: searchQueryUrl
        });
    }

    removeParam(param, sourceURL, removeRefParam) {
        var rtn = sourceURL.split("?")[0],
            key,
            key2 = '',
            params_arr = [],
            queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
        if (queryString !== "") {
            params_arr = queryString.split("&");
            if (removeRefParam) {
                for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                    key = params_arr[i].split("=")[0];
                    var value = params_arr[i].split("=")[1];
                    if (param === value) {
                        key2 = key.replace('n','v');
                        params_arr.splice(i, 1);
                        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                            key = params_arr[i].split("=")[0];
                            if (key2 === key) {
                                params_arr.splice(i, 1);
                            }
                        }
                    }
                }
                if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
            } else {
                for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                    key = params_arr[i].split("=")[0];
                    if (param === key) {
                        params_arr.splice(i, 1);
                    }
                }
                if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
            }
        }
        return rtn;
    }
    onFiltersClose(el) {
        $(el).closest('.js-dropdown').trigger('dropdown.close');
        this.$html.removeClass('is--filters-open');
    }

    onFiltersShow() {
        if (layout.isMobileView()) {
            this.$html.addClass('is--filters-open');
        }
    }

    onRefinementDisplayChange(params) {
        this.emitter.emit(`${this.id}.refinement.display.change`, params);
    }

    expandRefinements(refinementsId) {
        refinementsId.forEach((refinementId) => {
            this.getById(refinementId, (refinement) => {
                refinement.$el.addClass('is--expanded-desktop');
            });
        });
    }

    getExpandedRefinements() {
        return this.items.filter((refinement) => refinement.$el.hasClass('is--expanded-desktop'))
            .map((refinement) => refinement.id);
    }

    preExpandRefinements() {
        this.items[0].$el.addClass('is--expanded-desktop');
    }

    toggleClearFiltersBtn() {
        this.$el.find('.js-clear-filters').toggleClass('active', !this.isAnyRefinementValueSelected());
        this.$el.find('.js-refinements-apply').toggleClass('disable-btn', !this.isAnyRefinementValueSelected());
    }

    clearRefinementsSelection() {
        this.refinementsQueue = {
            attribute: {},
            category: {},
            price: {}
        };
        this.items.forEach((refinement) => refinement.clearSelectedValues());
    }

    isAnyRefinementValueSelected() {
        var selectedRefinementValues = [];
        var rerinementsPerType = {};
        for (var refinementType in this.refinementsQueue) {
            rerinementsPerType = this.refinementsQueue[refinementType];
            for (var refinementId in rerinementsPerType) {
                selectedRefinementValues = [...selectedRefinementValues, ...Object.values(rerinementsPerType[refinementId])];
            }
        }

        return !!selectedRefinementValues.length;
    }
}

