'use strict';
/* eslint-disable */
var ajax = require('_core_ext/ajax'),
    progress = require('_core/progress'),
    util = require('_core_ext/util'),
    imagesLoaded = require('imagesloaded'),
    dialog = require('_core_ext/dialog'),
    layout = require('_core_ext/layout');

import eventMgr from '_core_ext/core/eventMgr';
import { refreshKlarnaOSM } from '_core_ext/helpers/klarnaHelpers';
import slickSlider from '../../components/common/SlickSlider';
const emitter = eventMgr.getEmitter('productVariation');

var $cache = {},
    initialized = false,
    selectors = {
        pdpForm: '.pdpForm',
        variationDropDown: 'variation-select',
        stickyContent: '.js-product-details-sticky',
        swatchLink: '.c-product-variations__link',
        editModalSwatchLink: '.product-edit-modal .c-product-details__info .c-product-variations__link',
        productImages: '.js-product-images',
        productImagesWrapper: '.js-product-images-wrap',
        stickyVariationsBlock: '.js-product-details-sticky .c-product-details__sticky-variations-block',
        pdpBottomAddtoCartSticky: '.pdpBottomAddtoCartSticky',
        productStickyVariationTitle: '.pdpBottomAddtoCartSticky .c-product-details__sticky-variation-title',
        productStickyVariationItems: '.pdpBottomAddtoCartSticky .c-product-details__sticky-variation-items',
        pdpProductImageContainer: '#product-images .images-container'
    };

function initializeCache() {
    $cache = {
        document: $(document),
        pdpMain: $('#pdpMain'),
        pdpContent: $('#product-content'),
        productImageWrapper: $(selectors.productImagesWrapper),
        productImageContainer: $(selectors.productImages)
    };
}

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 * @param {String} hrefBrowser - url of the new product variant to set in Browser
 **/
var updateContent = function (href, hrefBrowser, linkRef, editCart, isVariantSelection) {
    var $pdpForm = $cache.document.find(selectors.pdpForm);
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };

    if (editCart) {
        progress.show($(linkRef).closest('.js-edit-cart'));
    } else {
        progress.show($cache.pdpMain);
    }
    ajax.load({
        url: util.appendParamsToUrl(href, params),
        callback: function (response) {
            var $content = $(`<div class="product-content-wrap"></div>`).append(response);
            
            // $cache.document.find(selectors.stickyContent).replaceWith(
            //     //$content.find(selectors.stickyContent).remove()
            // );
            if (editCart) {
                $(linkRef).closest('#product-content').html($content);
                $('.js-edit-cart').attr('data-variation-changed', 'true');
            } else {
                $cache.pdpContent.html($content);
                // Update the URL in browser
                window.history.replaceState({}, '', hrefBrowser);
            }
            $('div#product-number').html($content.find('#product-number').text().trim());
            $cache.document.trigger('product.variation.changed');
            emitter.emit('changed');
            eventMgr.execute('Ometria.track.page', {
                pid: $('.product-add-to-cart #pid').attr('value')
            }).catch(() => {});
            refreshKlarnaOSM();
            
            $(window).on('scroll', function () {
                var top = $(window).scrollTop(),
                    divBottom = $('.pdp-info-add-to-cart').offset().top + $('.pdp-info-add-to-cart').outerHeight();
                if (divBottom > top) {
                    $(selectors.pdpBottomAddtoCartSticky).removeClass('intoToTheView');
                } else {
                    $(selectors.pdpBottomAddtoCartSticky).addClass('intoToTheView');
                }
            })

            if (!editCart && !$(linkRef).hasClass('js-pdp-main-variation-link')) {
                $(selectors.pdpBottomAddtoCartSticky).addClass('intoToTheView');
                $(selectors.stickyVariationsBlock).addClass('showMobileSticky');
                $(selectors.pdpBottomAddtoCartSticky).addClass('showMobileSticky');
            }

            if (!editCart && $(selectors.productStickyVariationItems).is(':visible')) {
                if($(selectors.productStickyVariationTitle).not('.selected').length > 0) {
                    $(selectors.productStickyVariationTitle).not('.selected').first().trigger('click');
                } else {
                    $(selectors.stickyVariationsBlock).addClass('hideDesktopSticky');
                }
            }

            if(isVariantSelection) {
                updateMainImagesSection($(linkRef).data('product-images-url'), linkRef, editCart);
            } else {
                progress.hide();
            }
            $('.cancelButton').on('click', function () {
                $("#dialog-container").dialog("close")
            })
        }
    });
};

/**
 * @description update product images
 * @param {String} href - url of the new product variant
 **/
var updateMainImagesSection = function (href, linkRef, editCart) {
    var params = {
        format: 'ajax',
        editCart: editCart
    };

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        callback: function (response) {
            var $content = $('<div/>').append(response);
            if(editCart) {
                $('.product-edit-modal .js-edit-cart').find('.js-product-images').html(
                    $content
                );
                progress.hide();
            } else {
                $cache.productImageContainer.html(
                    $content
                );
            }

            imagesLoaded($cache.productImageContainer).on('done', function () {
                $cache.document.trigger('images.loaded');
                emitter.emit('imagesLoaded');
                
                if(layout.isMobileView() && !editCart){
                    slickSlider.slidePDPMainImage($(selectors.pdpProductImageContainer));
                }
                progress.hide();
            });

            if (!(layout.isMobileView()) && !$(linkRef).hasClass('js-pdp-sticky-variation-link') && !editCart) {
                $('html, body').animate({
                    scrollTop: 0
                  }, 600);
            }
        }
    });
};

var adaptImageContainerHeight = function () {
    if (layout.isMobileView()) {
        var imageContainerHeight = Math.ceil($cache.productImageContainer.outerHeight(false)) + 'px';
        $cache.productImageWrapper.css('min-height', imageContainerHeight);
    }
}


var enableColorSlickSilder = function ($swatchParentContainer) {
    var $swatch = $swatchParentContainer.find('.c-product-variations__color-sublist').find('li.c-product-variations__subitem');
    var swatchWidth = $swatch.outerWidth(true);
    var swatchContainerWidth = Math.floor($swatchParentContainer.width());
    var totalSwatchCount = $swatch.length;
    var swatchCountLimit = Math.floor(swatchContainerWidth/swatchWidth);

    return totalSwatchCount > swatchCountLimit;
}

var slideVariantSlider = function (productSwatchCount, pdpVariantsList) {
    let slickSlide = $(productSwatchCount+'.selected').parents('.slick-slide');
    if (!slickSlide.hasClass('slick-active')) {
        let slidesToShow = $(pdpVariantsList).slick('slickGetOption', 'slidesToShow');
        let slideToScroll = slickSlide.data('slick-index') - slidesToShow + 1;
        $(pdpVariantsList).slick('slickGoTo', slideToScroll);
    }
}

function initializeEvents() {
    // click on swatch - should replace product content with new variant
    $cache.document.on('click', selectors.swatchLink, selectors.editModalSwatchLink ,function (e) {
        e.preventDefault();
        var isVariantSelection = true;
        if ($(this).parent('.c-product-variations__subitem').hasClass('selected')) {
            return false;
        }

        var editCart = false;
        if ($('#dialog-container #pdpMain').hasClass('js-edit-cart')) {
            initializeCache();
            editCart = true;
            this.href = util.appendParamsToUrl(this.href, { editItem: true });
        }
        updateContent(this.href, this.getAttribute('hrefbrowser'), this, editCart, isVariantSelection);
    });

    // change drop down variation attribute - should replace product content with new variant
    $cache.pdpMain.on('change', selectors.variationDropDown, function () {
        if ($(this).val().length === 0) { return; }
        updateContent($(this).val());
    });

    /** This fix was disabled because it did not take into account that the variations groups may have a
     * different amount of images, so when the user switches from a variation group with N images to another
     * one with  M images and N > M or N < M the height of the images container is not calculated accurately
     * TODO: This issue must be addressed when working on the PDP updates
     * */
    // $(window).on('load', adaptImageContainerHeight);

    

    // To open the edit cart modal on cart page
    $(document).on('click', '.c-products-item__action-edit', function(e) {
        e.preventDefault();
        var targetPos, initialQty;
       
        if(pageContext.type.toUpperCase()=='cart'.toUpperCase()){//cart
            targetPos = $('#cart-table .c-products-table__item').index($(this).parents('.c-products-table__item')) + 1;
            initialQty = parseInt($(this).closest('.c-products-table__item').find('.js-item-quantity-field').data('value'));
        } else if(pageContext.type.toUpperCase()=='checkout'.toUpperCase()){//checkout - shipping/billing
            targetPos = $('.c-checkout-minicart__list .c-mini-cart__product-item-row').index($(this).parents('.c-mini-cart__product-item-row')) + 1;
            initialQty = parseInt($(this).closest('.c-mini-cart__product-item').find('.c-mini-cart-qty span.value').text());
        } else {//minibag
            targetPos = $('.c-mini-cart__wrapper .c-mini-cart__product-item-row').index($(this).parents('.c-mini-cart__product-item-row')) + 1;
            initialQty = parseInt($(this).closest('.c-mini-cart__product-item-row').find('.js-item-quantity-field').data('value'));
        }
        progress.show();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                dialogClass: "product-edit-modal",
                open: function () {
                    var slickSlide,
                        slidesToShow,
                        slideToScroll;
                    progress.hide();
                    var editModal = $('.product-edit-modal');
                    if(pageContext.type.toUpperCase()=='cart'.toUpperCase()){//cart
                        editModal.addClass('cartTriggered')
                    } else if(pageContext.type.toUpperCase()=='checkout'.toUpperCase()){//checkout - shipping/billing
                        editModal.addClass('triggeredFromCheckout');
                    } else { //minibag
                        editModal.addClass('miniBagTriggered');
                    }
                    $('.product-edit-modal').find('.js-edit-cart').attr('data-trigger-pos', targetPos);
                    $('.product-edit-modal').find('.js-edit-cart').attr('data-trigger-initial-qty', initialQty);
                    $('.cancelButton').on('click', function () {
                        $("#dialog-container").dialog("close")
                    })
                }
            }
        });
    });
}

var variations = {
    init: function() {
        if (initialized) {
            return;
        }
        initializeCache();
        initializeEvents();
        initialized = true;
    }
};

module.exports = variations;