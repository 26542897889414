'use strict';

var util = require('./../../../app_storefront_core_ext/cartridge/js/util'),
    cart = require('./pages/cart'),
    page = require('./../../../app_storefront_core_ext/cartridge/js/page'),
    layout = require('./../../../app_storefront_core_ext/cartridge/js/layout'),
    bonusProductsView = require('./../../../app_storefront_core/cartridge/js/bonus-products-view');

var emitter = window.eventMgr.getEmitter('miniCart');

var $cache = {},
    selectors = {
        miniCart: '.js-minicart',
        qtyBtn: '.js-qty',
        removeBtn: '.js-item-remove',
        itemQtyField: '.js-item-quantity-field',
        minicartOverlay: '.js-overlay-nav',
        miniCartContent: '.mini-cart-content',
        miniCartToggle: '.mini-cart-toggle',
        miniCartTotal: '.mini-cart-total',
        minicartRedirect: '.js-minicart-redirect'
    },
    cssConstants = {
        hideClass: 'hide-minicart',
        showOverlayClass: 'is--mini-cart-show',
        isExpandedClass: 'is--expanded'
    };

function miniCartToggle(showMinicart, $el) {
    if (showMinicart && $cache.$el.find(selectors.minicartRedirect).length) {
        page.redirect(Urls.showCart);
        return;
    }
    // Hide express checkout heading if express checkout is not available in minicart.
    if ($cache.$el.find(selectors.miniCartContent).find('.paypal-cart-buttons-container').length > 0 || $cache.$el.find(selectors.miniCartContent).find('.dw-apple-pay-button').length > 0) {
        $cache.$el.find(selectors.miniCartContent).find('div.mini-cart-payment-heading').removeClass('d-none');
    }

    var $minicartBody = $('.c-mini-cart__body');
    if (showMinicart) {
        setTimeout(() => {
            let paddingBottomVal = $('.c-mini-cart__sticky').outerHeight();
            if ($('.js_paypal_button') && $('.js_paypal_button').height() === 0) {
                paddingBottomVal += 40;
            }
            $minicartBody.css('padding-bottom', paddingBottomVal - 20 +'px');
        }, 100);
    }

    if (showMinicart && $(window).width() > 992) {
        let calcHeight = $('#header').outerHeight() + $('.c-mini-cart__caption').outerHeight();
        let bottomSlotHeight = $('#header-bottom-banner').outerHeight();
        let isbottomBannerVisible = $('#header-bottom-banner:visible').length;
        let miniCartPromo = $('.c-mini-cart-content .mini-cart-promo-message');
        let isMiniCartPromoBannerVisible = miniCartPromo.length;
        let miniCartPromoHeight = miniCartPromo.outerHeight();
        if (bottomSlotHeight > 0 && isbottomBannerVisible) {
            calcHeight -= bottomSlotHeight;
            $('.c-mini-cart-content').css('top', 'calc(100% - '+bottomSlotHeight+'px)');
        }
        if(isMiniCartPromoBannerVisible > 0){
            calcHeight += miniCartPromoHeight;
        }
        $minicartBody.css('height', 'calc(100vh - '+calcHeight+'px)');
    } else {
        if($el!= undefined && $el.length > 0 && $(window).width() < 768 ){
            page.redirect(Urls.showCart);
            return;
        }
        $('.c-mini-cart-content').css('top', '');
        $minicartBody.css('height', '');
    }

    $cache.$el.find(selectors.miniCartContent).toggleClass(cssConstants.hideClass, !showMinicart);
    $cache.html.toggleClass(cssConstants.showOverlayClass, showMinicart);
    $cache.minicartOverlay.toggleClass(cssConstants.isExpandedClass, showMinicart);
    $cache.body.toggleClass('mini-cart-show', showMinicart);
    if (!showMinicart) {
        $cache.document.trigger('minicart.close', false);
    }
    $cache.document.trigger('minicart.show');
    emitter.emit('show');
    $cache.minicartOverlay.removeClass('is--mobile-expanded');
    if($('body').hasClass('mini-cart-show')) {
        $('body').removeClass('scroll-down');
    }
}

function initializeCache() {
    $cache.document = $(document);
    $cache.html = $(document.documentElement);
    $cache.body = $(document.body);
    $cache.$el = $(selectors.miniCart);
    $cache.minicartOverlay = $cache.document.find(selectors.minicartOverlay);
}

function initializeEvents() {
    $cache.$el
        .on('click', selectors.qtyBtn, function (e) {
            e.stopPropagation();
            e.preventDefault();
            cart.updateQty(this);
            if (pageContext.ns === 'cart') {
                $cache.minicartOverlay.removeClass(cssConstants.isExpandedClass);
                $(document).trigger('minicart.close', true);
            }
        })
        .on('click', selectors.removeBtn, function (e) {
            e.preventDefault();
            var $lineItem = $(this).closest('.js-line-item-row');
            var url = util.appendParamToURL(Urls.removeProductFromMinicart, 'uuid', $lineItem.data('uuid'));
            let removeItem = true;
            let $undoLink = $lineItem.find('.product-undo-link');

            $lineItem.find('.product-undo').addClass('active');
            $undoLink.on('click', function (e) {
                removeItem = false;
                $lineItem.find('.product-undo').removeClass('active');
            });
            setTimeout(function() {
                if(removeItem) {
                    if ($lineItem.find('input.js-item-quantity-field').val() > 0) {
                        $cache.document.trigger('product.removeFromCart', [$lineItem, $lineItem.find('input.js-item-quantity-field').val()]);
                        if (pageContext.ns === 'cart') {
                            $cache.minicartOverlay.removeClass(cssConstants.isExpandedClass);
                            $(document).trigger('minicart.close', true);
                        }
                    } else {
                        $cache.document.trigger('product.removeFromCart', [$lineItem, Math.abs(cart.removedQty)]);
                    }
                    cart.updateCart(url);
                }
            }, 3000);
        })
        .on('click', selectors.miniCartTotal, () => {
            $('.header__search-form-close').trigger('click');
            miniCartToggle(true, $cache.$el);
            $cache.document.trigger('menu.close');
            //emitter.emit('toggled');
        })
        .on('change', selectors.itemQtyField, function () {
            var prevCount=0;
            if (cart.qtyEvent === 'added') {
                prevCount = parseInt($(this).val(), 10) - 1;
            } else if (cart.qtyEvent === 'removed') {
                prevCount = parseInt($(this).val(), 10) + 1;
            }
            cart.updateQtyOnInput(this, (parseInt($(this).val(), 10) === 0), prevCount);
        });

    $cache.document
        .on('click', selectors.miniCartToggle + ', ' + selectors.minicartOverlay, () => miniCartToggle(false))
        .on('minicart.close', () => {
            $cache.$el.find(selectors.miniCartContent).toggleClass(cssConstants.hideClass, true);
            $cache.body.removeClass('mini-cart-show');
            $cache.html.toggleClass(cssConstants.showOverlayClass, false);
        });

    if (layout.isMobile() || layout.isTablet()) {
        $(selectors.itemQtyField).prop('disabled', true);
    }
}

var minicart = {
    init: function () {
        initializeCache();
        initializeEvents();
    },

    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function (html) {
        this.update(html);
        util.scrollBrowser(0);
        miniCartToggle(true);
        bonusProductsView.loadBonusOption();
    },

    update: function (html) {
        var $update = $(html).filter((index, node) => {
            return node.nodeName === 'DIV';
        }).html();
        $cache.$el.html($update);
    },

    loadBonusProducts: bonusProductsView.loadBonusOption,

    miniCartToggle: miniCartToggle
};

module.exports = minicart;
